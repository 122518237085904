import React, { useEffect, useState } from "react";
import {
  ReferenceField,
  Filter,
  TextField,
  useGetIdentity,
  ReferenceInput,
  FunctionField,
  useNotify,
  BulkDeleteButton,
  useGetList,
  useRefresh,
  BulkDeleteWithConfirmButton,
} from "react-admin";
import { IfCanAccess, List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import CreateContactList from "./CreateContactList";
import SendSmsAction from "./SendSmsAction";
import { isEmailValid, sanitizePhoneNumber } from "../../services/common-utils/functions";
import FormInput from "../../components/form/FormInput";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import ContactsCreate from "./ContactsCreate";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { Download } from "lucide-react";
import api from "../../services/apiWithAuth";
import { FilterButton } from "../../components/react-admin/FilterButton";
import { ListActions } from "../../components/react-admin/ListActions";
import NewImportButtons from "../../components/importButtons/NewImportButtons";
import CustomExportButton from "../../components/react-admin/CustomExportButton";
import PageTitle from "../../components/navigation/PageTitle";

const BulkActionButtons = () => {
  return (
    <>
      <CreateContactList />
      <IfCanAccess action="delete">
        <BulkDeleteWithConfirmButton mutationMode="pessimistic" confirmColor="warning" />
      </IfCanAccess>
    </>
  );
};

const getFields = (contactListOptions: any = null) => {
  const fields: any = [
    {
      // Visible in table header and when matching columns.
      label: "Prénom",
      // This is the key used for this field when we call onSubmit.
      key: "firstname",
      // Allows for better automatic column matching. Optional.
      alternateMatches: ["Prénom", "Prenom", "prenom", "prénom"],
      // Used when editing and validating information.
      fieldType: {
        // There are 3 types - "input" / "checkbox" / "select".
        type: "input",
      },
      // Used in the first step to provide an example of what data is expected in this field. Optional.
      example: "Stéphanie",
      // Can have multiple validations that are visible in Validation Step table.
      validations: [
        {
          // Can be "required" / "unique" / "regex"
          rule: "required",
          errorMessage: "Prénom requis",
          // There can be "info" / "warning" / "error" levels. Optional. Default "error".
          level: "error",
        },
      ],
    },
    {
      label: "Nom",
      key: "lastname",
      alternateMatches: ["Nom", "Nom de famille"],
      fieldType: {
        type: "input",
      },
      example: "Dumas",
      validations: [
        {
          rule: "required",
          errorMessage: "Nom requis",
          level: "error",
        },
      ],
    },
    {
      label: "Numéro de téléphone",
      key: "phonenumber",
      alternateMatches: ["Numéro de téléphone", "Téléphone", "Telephone", "Téléphone pro", "telephone pro"],
      fieldType: {
        type: "input",
      },
      example: "+33601020304",
      validations: [
        {
          rule: "required",
          errorMessage: "Numéro de téléphone requis",
          level: "error",
        },
        {
          rule: "unique",
          errorMessage: "Numéro de téléphone déjà existant",
          level: "error",
        },
      ],
    },
    {
      label: "Email",
      key: "email",
      alternateMatches: ["Email", "Mail", "Adresse mail", "Adresse email"],
      fieldType: {
        type: "input",
      },
      example: "stephanie.dumas@gmail.com",
      validations: [
        {
          rule: "required",
          errorMessage: "Email requis",
          level: "error",
        },
      ],
    },
  ];
  if (contactListOptions && contactListOptions?.length > 0) {
    fields.push({
      label: "Liste de contact",
      key: "contactListId",
      alternateMatches: ["Liste de contact", "Liste contact", "Liste contacts"],
      fieldType: {
        type: "select",
        options: contactListOptions,
      },
      example: "Ma liste",
    });
  }
  return fields;
};

const ContactsList = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  const refresh = useRefresh();
  // const [importFile, setImportFile] = useState(false);
  const [companyCredits, setCompanyCredits] = useState(null);

  const { data: contactLists } = useGetList("contactLists", {
    pagination: { page: 1, perPage: 100 },
    filter: { groupId: props?.company || identity?.company },
    sort: { field: "name", order: "ASC" },
  });

  useEffect(() => {
    const company = props?.company || identity?.company;
    if (company && companyCredits === null) {
      const asyncCall = async () => {
        const getCompanyCreditsRes = await api.get("/utils/getCredits/" + company);
        setCompanyCredits(getCompanyCreditsRes.data[0].credits);
      };
      asyncCall();
    }
  }, [companyCredits, identity?.company, props?.company]);

  const onSubmit = async (data: any) => {
    const {
      data: { contactsCreated },
    } = await api.post("/contacts/import", {
      data,
      groupId: props.company || identity?.company,
    });
    refresh();
  };

  return isLoading ? (
    <></>
  ) : (
    <>
      {!props?.withoutTitle && (
        <PageTitle
          text="Contacts"
          createButton={<ContactsCreate />}
          type="list"
          breadcrumbListLabel="Liste de contacts"
        />
      )}
      <List
        perPage={10}
        {...props}
        debounce={1000}
        filter={
          props.group_id
            ? { group_id: props.group_id }
            : ["Client", "Indirect"].includes(identity?.status)
            ? { group_id: identity?.company }
            : {}
        }
        filters={
          <Filter {...props}>
            <FormInput placeholder="Rechercher" name="query" type="search" alwaysOn />
            {!props.group_id && identity?.status === "Collaborateur" && (
              <ReferenceInput
                source="group_id"
                reference="companies"
                sort={{ field: "company", order: "ASC" }}
                perPage={1000}
                label="Organisation"
                hideLabel
              >
                <AutocompleteInput
                  helperText="Organisation"
                  optionText="company"
                  filterToQuery={(search: any) => ({ company: search })}
                />
              </ReferenceInput>
            )}
          </Filter>
        }
        actions={
          <ListActions>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
              <NewImportButtons
                {...props}
                fields={getFields(
                  contactLists?.map((contactList: any) => ({ label: contactList.name, value: contactList.id })) || null
                )}
                xlsxTemplate={process.env.REACT_APP_TEMPLATE_IMPORT_CONTACTS_EXCEL}
                csvTemplate={process.env.REACT_APP_TEMPLATE_IMPORT_CONTACTS}
                onSubmit={onSubmit}
                rowHook={async (data, addError) => {
                  if (data.email && !isEmailValid(data.email)) {
                    addError("email", { message: data.email + " n'est pas une adresse email valide", level: "error" });
                  }
                  const {
                    data: { exists },
                  } = await api.post("/contacts/checkExistingContact", {
                    groupId: props.company || identity?.company,
                    phonenumber: sanitizePhoneNumber(data.phonenumber),
                  });
                  if (exists) {
                    addError("phonenumber", {
                      message: "Ce numéro de contact est déjà utilisé par un de vos contact",
                      level: "error",
                    });
                  }
                  return { ...data, phonenumber: sanitizePhoneNumber(data.phonenumber) };
                }}
              />
              {/* <IconButton onClick={() => setImportFile(true)} sx={{ mr: [1, 2], width: 35, height: 35, p: 0 }}>
              <Download size={18} />
            </IconButton> */}
            </Box>
            <CustomExportButton
              fields={[
                { variable: "firstname", label: "Prénom" },
                { variable: "lastname", label: "Nom" },
                { variable: "email", label: "Email" },
                { variable: "phonenumber", label: "Numéro de téléphone" },
                //todo : use id to find contactList where contact is
                // {
                //   variable: "id",
                //   label: "Liste de contact",
                //   transform: (contactId: any) => (contactId),
                // },
              ]}
              fileName="contacts"
              maxResults={100}
            />
          </ListActions>
        }
        sort={{ field: "firstname", order: "ASC" }}
        exporter={false}
        empty={false}
      >
        <CustomDatagrid bulkActionButtons={<BulkActionButtons />}>
          <FunctionField
            sortBy="firstname"
            label="Nom complet"
            render={(record: any) => {
              const formattedFirstName = record.firstname[0].toUpperCase() + record.firstname.slice(1);
              const formattedLastName = record.lastname.toUpperCase();
              return (
                <div style={{ display: "flex", flexDirection: "row", gap: "12px", alignItems: "center" }}>
                  <b>{`${formattedFirstName} ${formattedLastName}`}</b>
                </div>
              );
            }}
          />
          <TextField label="Numéro de téléphone" source="phonenumber" sortable={false} />
          <TextField label="Adresse email" source="email" sortable={false} />
          {!props.group_id && identity?.status === "Collaborateur" && (
            <ReferenceField label="Organisation" source="group_id" reference="companies" link={false}>
              <TextField source="company" />
            </ReferenceField>
          )}
          {identity?.licenseId && (
            <FunctionField
              label="Actions"
              render={(record: any) => {
                // const formattedFirstName = record.firstname[0].toUpperCase() + record.firstname.slice(1);
                // const formattedLastName = record.lastname.toUpperCase();
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <SendSmsAction record={record} companyCredits={companyCredits} />
                  </div>
                );
              }}
            />
          )}
        </CustomDatagrid>
      </List>
    </>
  );
};

export default ContactsList;
